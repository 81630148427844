import { createAsyncThunk } from "@reduxjs/toolkit";

import { isErrorResult, makeApiRequest, ENDPOINTS_ADMIN } from "services";
import { ErrorResult } from "services/types";

import { AdminSurveyResponse, AdminSurveyPayload } from "./types";

export const getAdminSurveys = createAsyncThunk<AdminSurveyResponse, AdminSurveyPayload, { rejectValue: ErrorResult }>(
  "admin/get-all-surveys",
  async (params, { rejectWithValue }) => {
    const result = await makeApiRequest<AdminSurveyResponse>({
      method: "GET",
      url: `${ENDPOINTS_ADMIN.getAdimSurveys}`,
      params,
      paramsSerializer: {
        indexes: null, // no brackets at all
      },
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);
