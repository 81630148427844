import { address } from "@ton/core";
import { Network } from "./chains";

const contractsAddresses = {};

const tonInitAddress = {
  [Network.TON]: address("0QDfaviQSL-ZZFYJDHY37h90LmvAXUwgQL0FNCliT_h1kdrz"),
  [Network.TON_TEST]: address("0QDfaviQSL-ZZFYJDHY37h90LmvAXUwgQL0FNCliT_h1kdrz"),
};

export { contractsAddresses, tonInitAddress };
