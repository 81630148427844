import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { getAdminSurveys } from "./actions";
import { getSurveyTags } from "store/create-survey/survey-tags/actions";

import { AdminSurveyPayload, UserSurveyState } from "./types";
import { SurveyTags } from "store/create-survey/survey-tags/types";
const initialState: UserSurveyState = {
  pending: false,
  error: null,
  selectedTags: [],
  tags: [],
  adminSurveys: [],
  adminSurveyPayload: {
    pageOrder: "desc",
    pageSize: 10,
    pageNumber: 1,
    pageOrderBy: "createdAt",
    statuses: [],
  },
};

const adminSurveySlice = createSlice({
  name: "adminSurvey",
  initialState: {
    ...initialState,
  },
  reducers: {
    resetAdminSurveyState: () => {
      return initialState;
    },
    resetAdminSurveys: state => {
      state.adminSurveys = [];
    },
    // setUserSurveyActiveTab: (state, action: PayloadAction<string>) => {
    //   state.activeTab = action.payload;

    //   state.surveyPayload = {
    //     ...state.surveyPayload,
    //     status: userActiveSurveyStatuses[action.payload as UserActiveSurveysActiveTab],
    //     pageNumber: 1,
    //   };
    // },
    setAdminSurveySelectedTags: (state, action: PayloadAction<SurveyTags[]>) => {
      state.selectedTags = action.payload;

      state.adminSurveyPayload = {
        ...state.adminSurveyPayload,
        tagsIds: action.payload.map(({ id }) => id),
        pageNumber: 1,
        pageSize: 10,
      };
    },
    setAdminSurveyPayload: (state, action: PayloadAction<Partial<AdminSurveyPayload>>) => {
      state.adminSurveyPayload = { ...state.adminSurveyPayload, ...action.payload };
    },
  },
  extraReducers: builder => {
    builder
      // Get Admin Surveys
      .addCase(getAdminSurveys.pending, state => {
        state.pending = true;
      })
      .addCase(getAdminSurveys.fulfilled, (state, action) => {
        const { items, total } = action.payload;
        state.totalSurveys = total;
        if (state.adminSurveyPayload.pageNumber === 1) {
          state.adminSurveys = items;
        } else {
          state.adminSurveys = [...state.adminSurveys, ...items];
        }
        state.error = null;
        state.pending = false;
      })
      .addCase(getAdminSurveys.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      // get Admin Survey Tags
      .addCase(getSurveyTags.pending, state => {
        state.pending = true;
      })
      .addCase(getSurveyTags.fulfilled, (state, action) => {
        const { items } = action.payload;
        state.tags = items;
        state.error = null;
        state.pending = false;
      })
      .addCase(getSurveyTags.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      });
  },
});

export const { resetAdminSurveyState, setAdminSurveyPayload, resetAdminSurveys, setAdminSurveySelectedTags } =
  adminSurveySlice.actions;

export default adminSurveySlice;
