import { useEffect, useState } from "react";
import { getHttpV4Endpoint } from "@orbs-network/ton-access";
import { TonClient4 } from "@ton/ton";

// TODO: check it before prod
const network = process.env.REACT_APP_ENV === "development" ? "testnet" : "testnet";

const useGetTonClient = () => {
  const [tonClient, setTonClient] = useState<TonClient4 | null>(null);

  useEffect(() => {
    getHttpV4Endpoint({ network }).then(endpoint => {
      const client = new TonClient4({
        endpoint,
      });
      setTonClient(client);
    });
  }, []);

  return { tonClient };
};

export default useGetTonClient;
