import { Fragment } from "react/jsx-runtime";
import { Menu, Transition } from "@headlessui/react";
import { Button } from "@sekmet/radix-ui-themes";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

import { Icons } from "components/icons";

import { useTheme } from "context";

import { useAppDispatch } from "store/store";
import { showModal } from "store/modal";
import { setSurveyGenerateType, resetCreateSurveyState } from "store/create-survey/survey";

import { ROUTES } from "router/routes";

import { SurveyGenerateTypeEnum } from "store/create-survey/survey/types";

import { ModalProps } from "store/modal/types";
import { GenerateSurveyModalProps } from "components/modals/components/generate-survey-modal/types";

const CreateSurveyButton: React.FC<{ buttonText?: string }> = ({ buttonText = "Create" }) => {
  const { theme } = useTheme();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const handleSurveyType = (type: SurveyGenerateTypeEnum) => {
    dispatch(setSurveyGenerateType(type));
    const _showModal = showModal as ModalProps<GenerateSurveyModalProps>;

    dispatch(
      _showModal({
        modalName: "generateSurveyModal",
        // clickOutsideHandler: () => {},
        title: `Generate a ${type === SurveyGenerateTypeEnum.campaign ? "Survey" : "Quiz"}`,
        props: {
          isQuiz: type === SurveyGenerateTypeEnum.quiz,
        },
      }),
    );
  };

  const handleSurveyTypeClick = (path: string) => {
    dispatch(resetCreateSurveyState());
    navigate(`/${ROUTES.business}/${ROUTES.surveys}/${path}`);
  };
  return (
    <Menu as="div" className="relative z-1 rounded-lg ">
      <Menu.Button
        size={"3"}
        as={Button}
        className="light-button-main relative flex rounded-full border-none bg-transparent"
        style={{
          backgroundColor: "#A855F7",
          borderRadius: "12px",
          padding: "12px 16px",
          fontSize: "12px",
          fontWeight: "bold",
          cursor: "pointer",
        }}
      >
        <div className="desktop">{buttonText}</div>
        <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.99967 1.33301V10.6663M1.33301 5.99967H10.6663"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          style={{ background: theme === "dark" ? "#1f1f22" : "white" }}
          className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-lg py-0 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <Menu.Item>
            {({ active }) => (
              <div
                onClick={() => handleSurveyTypeClick(ROUTES.createQuiz)}
                className={clsx(
                  active ? (theme === "dark" ? "bg-[#3F3F46]" : "bg-gray-100") : "",
                  "flex px-4 py-3 text-xs text-gray-900",
                )}
              >
                <span
                  className="font-bold"
                  style={{
                    color: theme === "dark" ? "white" : "#1f1f22",
                  }}
                >
                  Create Quiz
                </span>
              </div>
            )}
          </Menu.Item>
          <div
            className={clsx(
              "h-[0px] w-full",
              theme === "dark" ? "border border-solid border-[#27272A]" : "border border-solid border-[#E4E4E7]",
            )}
          />
          <Menu.Item>
            {({ active }) => (
              <div
                onClick={() => handleSurveyTypeClick(ROUTES.createSurvey)}
                className={clsx(
                  active ? (theme === "dark" ? "bg-[#3F3F46]" : "bg-gray-100") : "",
                  "flex px-4 py-3 text-xs text-gray-900",
                )}
              >
                <span
                  className="font-bold"
                  style={{
                    color: theme === "dark" ? "white" : "#1f1f22",
                  }}
                >
                  Create Survey
                </span>
              </div>
            )}
          </Menu.Item>

          <div
            className={clsx(
              "h-[0px] w-full",
              theme === "dark" ? "border border-solid border-[#27272A]" : "border border-solid border-[#E4E4E7]",
            )}
          />
          <Menu.Item>
            {({ active }) => (
              <div
                onClick={() => handleSurveyType(SurveyGenerateTypeEnum.quiz)}
                className={clsx(
                  active ? (theme === "dark" ? "bg-[#3F3F46]" : "bg-gray-100") : "",
                  "flex px-4 py-2 text-xs text-gray-900",
                )}
              >
                <span
                  className="mr-3 mt-1.5 font-bold"
                  style={{
                    color: theme === "dark" ? "white" : "#1f1f22",
                  }}
                >
                  Create Quiz
                </span>

                <Icons.CreateAIIcon />
              </div>
            )}
          </Menu.Item>

          <div
            className={clsx(
              "h-[0px] w-full",
              theme === "dark" ? "border border-solid border-[#27272A]" : "border border-solid border-[#E4E4E7]",
            )}
          />
          <Menu.Item>
            {({ active }) => (
              <div
                onClick={() => handleSurveyType(SurveyGenerateTypeEnum.campaign)}
                className={clsx(
                  active ? (theme === "dark" ? "bg-[#3F3F46]" : "bg-gray-100") : "",
                  "flex px-4 py-2 text-xs text-gray-900",
                )}
              >
                <span
                  className="mt-1.5 whitespace-nowrap font-bold"
                  style={{
                    color: theme === "dark" ? "white" : "#1f1f22",
                  }}
                >
                  Create Survey
                </span>

                <Icons.CreateAIIcon />
              </div>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default CreateSurveyButton;
