// import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";

import { stripePromise } from "services";

// eslint-disable-next-line
import { Loader } from "components";
import StripeSubscriptionsForm from "./StripeSubscriptionsForm";
import { useGetSubscriptionsIntent } from "../../hooks";

import type { StripeSubscriptionsProps } from "./types";
import type { StripeElementsOptions } from "@stripe/stripe-js";
import { useTheme } from "context";

const StripeSubscriptions: React.FC<StripeSubscriptionsProps> = ({ type }) => {
  const { intent, isLoading } = useGetSubscriptionsIntent(type);
  const { theme } = useTheme();

  const options: StripeElementsOptions = {
    clientSecret: intent?.clientSecret,
    appearance: {
      theme: theme === "light" ? "stripe" : "night",
      variables: {
        colorIcon: "#6772e5",
        colorBackground: theme === "light" ? "#ededed" : "#18181b",
      },
    },
  };

  if (!intent || !intent.clientSecret || isLoading) {
    return <Loader />;
  }

  return (
    <Elements stripe={stripePromise} options={options}>
      <StripeSubscriptionsForm secret={intent.clientSecret} />
    </Elements>
  );
};

export default StripeSubscriptions;
