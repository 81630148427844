const contractPerNetwork = {
  mainnet: "",
  testnet: "native-blackkilt7020.testnet",
};

const contractNearNft = {
  mainnet: "",
  testnet: "nft-otherbat2195.testnet",
};

export const NetworkId = "testnet";
export const NearContract = contractPerNetwork[NetworkId];
export const NearNftContract = contractNearNft[NetworkId];
